<template>
  <v-dialog v-model="modalData.dialog" width="1250px" persistent scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        New equipment
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn
              @click="handleCloseModalForm"
              style="float: right; cursor: pointer"
              icon
              color="#26223c"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-container>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="formModel.equipment_number"
                  label="Search equipment"
                  :rules="nameRules"
                  :id="dynamicID"
                  @keydown.enter.prevent="handleSearchEquipment"
                  :error-messages="messages['equipment_number']"
                  @keyup="messages['equipment_number'] = ''"
                ></v-text-field>
                {{ formModel.equipment_name }} -
                {{ formModel.equipment_number }}
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-autocomplete
                  v-model="formModel.user_id"
                  :items="employeeCollection"
                  item-text="fullName"
                  item-value="user_id"
                  label="Select owner"
                  :rules="requiredRules"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-select
                  v-if="statuses.system"
                  v-model="formModel.status"
                  :items="statuses.companySettings.department"
                  label="Status"
                  item-text="value"
                  item-value="key"
                />
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="formModel.frequency"
                  label="Maintenance period (month)"
                  type="number"
                  min="0"
                  :id="dynamicID"
                  :error-messages="messages['frequency']"
                  @keyup="messages['frequency'] = ''"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="formModel.comment"
                  label="Comment"
                  :error-messages="messages['comment']"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" sm="3" md="3">
                <label for="datepicker-full-width">Mintenance date</label>
                <b-form-datepicker
                  v-model="inputData.date"
                  calendar-width="100%"
                  locale="en"
                  class="mb-2"
                  start-weekday="1"
                  style="max-width: 260px"
                  @context="messageDate = ''"
                ></b-form-datepicker>
                <small v-if="messageDate" style="color: red">
                  {{ messageDate }}
                </small>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  v-model="inputData.comment"
                  label="Comment"
                  class="mt-3"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <v-text-field
                  v-model="inputData.po_number"
                  label="PO ID"
                  class="mt-3"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <v-select
                  v-if="statuses.system"
                  v-model="inputData.status"
                  class="mt-3"
                  :items="statuses.maintenance.maintenance"
                  label="Status"
                  item-value="key"
                >
                  <template slot="selection" slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ $t("EQUIPMENT.status_" + data.item.key) }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t("EQUIPMENT.status_" + data.item.key) }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <v-btn
                  color="primary mr-2"
                  elevation="2"
                  class="mt-4"
                  @click="handleAddToList"
                  >Add to list</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-data-table
                  dense
                  :headers="headers"
                  :items="computedMaintenances"
                  item-key="name"
                  class="elevation-1"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="handleEditItem(item)">
                      mdi-pencil
                    </v-icon>

                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                  <template v-slot:item.status="{ item }">
                    {{ $t("EQUIPMENT.status_" + item.status) }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <DeleteModalDialog
              :dialogDelete="dialogDelete"
              @closeDelete="handleCloseDelete"
              @deleteItemConfirm="handleDeleteItemConfirm"
            >
            </DeleteModalDialog>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { ENDPOINT, PERMISSION_TO } from "./Equipments";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";
import Swal from "sweetalert2";

const TRANSLATED_ATRIBUTES = [];

export const initialFormData = () => ({
  id: null,
  status: 1,
  user_id: null,
  equipment_name: null,
  equipment_number: null,
  equipment_type: null,
  equipment_id: null,
  frequency: null,
  comment: null,
  maintenances: [],
  translations: [],
});

export const initialInputData = () => ({
  maintenance_id: null,
  status: 0,
  date: null,
  po_number: null,
  comment: null,
});

export default {
  name: "EquipmentUnitForm",
  components: { DeleteModalDialog },
  props: ["modalData", "permissions", "statuses", "employeeCollection"],
  mixins: [formModelMixins],
  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      permissionTo: PERMISSION_TO,
      endPoint: ENDPOINT,

      formValid: false,
      messages: {},
      messageDate: null,
      nameRules: [
        (v) => !!v || "This field is required",
        (v) =>
          (!!v && v.length < 255) || "This field must be max 255 characters",
      ],
      requiredRules: [(v) => !!v || "This field is required"],

      inputData: initialInputData(),
      headers: [
        {
          text: "Maintenance date",
          value: "date",
        },
        { text: "Comment", value: "comment" },
        { text: "PO ID", value: "po_number" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions" },
      ],

      editedItem: null,
      dialogDelete: false,
    };
  },
  computed: {
    // ...mapGetters([CURRENCY_COLLECTION]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? "Edit - " + this.formModel.equipment_name
        : "New equipment";
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },

    computedMaintenances() {
      return this.formModel.maintenances;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.inputData = initialInputData();
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getActiveLanguage();
        });
        if (value.editedId) {
          this.loader = true;
          ApiService.get(ENDPOINT + value.editedId)
            .then(({ data }) => {
              if (!data.equipment_name) {
                let appLocale = i18nService.getActiveLanguage();
                if (data.equipment_translations) {
                  data.equipment_name = data.equipment_translations[appLocale]
                    ? data.equipment_translations[appLocale].name
                    : data.equipment_translations[0].name;
                }
              }
              this.formModel = Object.assign({}, data);
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());

          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    // ...mapActions([FETCH_CURRENCY]),

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        // this.modalData.loading = true;
        model.equipment_id = model.equipment_id.toString();
        /**Delete unused translations */
        if (TRANSLATED_ATRIBUTES.length > 0) {
          let translations = {};
          i18nService.languages.forEach((item) => {
            let langCode = item.lang;
            if (
              model.translations[langCode] &&
              model.translations[langCode].name != ""
            ) {
              translations[langCode] = Object.assign(
                {},
                model.translations[langCode]
              );
            }
          });
          if (Object.keys(translations).length != 0) {
            model.translations = translations;
          }
        }
        /**Delete unused translations */
        this.loader = true;
        if (model.id) {
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(ENDPOINT, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    handleSearchEquipment() {
      this.loader = true;
      ApiService.post("maintenance/equipment/search", {
        number: this.formModel.equipment_number,
      })
        .then(({ data }) => {
          //this.$emit("saveModalForm");
          this.formModel.equipment_id = data.equipment_id;
          this.formModel.equipment_type = data.equipment_type;
          this.formModel.equipment_name = data.name;
          this.formModel.equipment_number = data.equipment_number;

          if (!this.formModel.equipment_name) {
            let appLocale = i18nService.getActiveLanguage();
            if (data.translations) {
              this.formModel.equipment_name = data.translations[appLocale]
                ? data.translations[appLocale].name
                : data.translations[0].name;
            }
          }
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loader = false;
        });
    },

    handleAddToList() {
      if (!this.inputData.date) {
        this.messageDate = "Date is required!";
        return;
      }
      if (
        this.formModel.maintenances.find(
          (item) => item.date == this.inputData.date
        )
      ) {
        Swal.fire({
          title: "",
          text: "This date is already on the list!",
          icon: "error",
        });
        return;
      }

      if (this.inputData.po_number && this.inputData.po_number.length == 0)
        this.inputData.po_number = null;
      this.formModel.maintenances.push(Object.assign({}, this.inputData));
      this.inputData = initialInputData();
    },

    deleteItem(item) {
      this.editedItem = this.computedMaintenances.indexOf(item);
      this.dialogDelete = true;
    },

    handleDeleteItemConfirm() {
      this.formModel.maintenances.splice(this.editedItem, 1);
      this.handleCloseDelete();
    },

    handleCloseDelete() {
      this.editedItem = null;
      this.dialogDelete = false;
    },

    handleEditItem(item) {
      this.inputData = Object.assign({}, item);
      let index = this.computedMaintenances.indexOf(item);
      this.formModel.maintenances.splice(index, 1);
    },
  },

  async mounted() {
    this.setTranslatedAttributes();
  },
};
</script>
